// import { TextField, Button, Grid, Card } from "@mui/material"; 
// import React, { useRef } from "react";
// import emailjs from '@emailjs/browser';

// const ContactForm = () => {
//     const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm('servicesssa_lcdikxhd', 'template_asara4yf1l', form.current, 'hHk1asasgJukMYaFgCsxP')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//   };
//   return (
//     <form ref={form} onSubmit={sendEmail} style={{marginTop:"20px"}}>
//       <Grid container spacing={2} direction="column" alignItems="stretch">
//         <Grid item>
//           <TextField
//             id="first_name"
//             name='first_name'
//             label="First Name"
//             variant="outlined"
//             fullWidth
//           />
//         </Grid>
//          <Grid item>
//           <TextField
//             id="last_name"
//              name='last_name'
//             label="Last Name"
//             variant="outlined"
//             fullWidth
//           />
//         </Grid>

//         <Grid item>
//           <TextField
//             id="from_mail"
//             label="Email"
//             name='from_mail'
//             variant="outlined"
//             fullWidth
//           />
//         </Grid>

//         <Grid item>
//           <TextField
//             id="message"
//             name="message"
//             label="Message"
//             variant="outlined"
//             multiline
//             rows={4}
//             fullWidth
//           />
//         </Grid>


//         <Grid  item sx={{textAlign:'center'}}>
//           <Button variant="contained" type="submit"  sx={{padding:'15px',width:'150px'}}>
//             Submit
//           </Button>
//         </Grid>
//       </Grid>
//     </form>
//   );
// };

// export default ContactForm;

import React, { useState } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const [fullname, setFullname] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [statusMessage, setStatusMessage] = useState("");
    const [statusType, setStatusType] = useState("");

    const [errors, setErrors] = useState({
        fullname: false,
        mobile: false,
        email: false,
        message: false
    });

    const errorValidation = () => {
        let err = {};
        if (fullname.length == 0) {
            err.fullname = "Enter your First Name"
        }
        if (email.length == 0) {
            err.email = "Enter your Email Id"
        }
        if (email.length >= 1) {
            let pattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.(com|net|org|biz|company|corp|tech|io)$/;

            let k = pattern.test(email);
            if (!k) {
                err.email = "Enter a valid business email format";
            }
        }
        if (mobile.length == 0) {
            err.mobile = "Enter your Phone Number"
        }
        if (mobile.length >= 1) {
            if (mobile.length !== 10) {
                err.mobile = "Enter Valid Phone Number"
            }
        }
        if (message.length == 0) {
            err.message = "Enter your Designation"
        }
        setErrors(err);
        return err;
    }

    const sendEmail = (e) => {
        e.preventDefault()
        let errs = errorValidation()

        if (Object.keys(errs).length == 0) {
            console.log("first")
            emailjs.sendForm('service_lcklhqg', 'template_kxa8m2n', e.target, 'xxtj8Z3Fnro60XkBd')
                .then((result) => {
                    console.log(result.text);
                    setStatusMessage("Your message has been sent successfully!");
                    setStatusType("success");

                    setFullname("");
                    setMobile("");
                    setEmail("");
                    setMessage("");
                }, (error) => {
                    console.log(error.text);
                    setStatusMessage("Something went wrong. Please try again later.");
                    setStatusType("error");
                });
        }
    };

    return (
        <form onSubmit={sendEmail} style={{ marginTop: "20px" }}>
            <Grid container spacing={2} direction="column" alignItems="stretch">
                <Grid item>
                    <TextField
                        id="fullname"
                        label="Full Name*"
                        variant="outlined"
                        fullWidth
                        name="fullname"
                        value={fullname}
                        onChange={(e) => setFullname(e.target.value)}
                        error={errors.fullname} // Show error state
                        helperText={errors.fullname ? errors?.fullname : ""}
                    />
                </Grid>

               
                <Grid item>
                    <TextField
                        id="mobile"
                        label="Mobile*"
                        type='Number'
                        variant="outlined"
                        fullWidth
                        name="mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        error={errors.mobile} // Show error state
                        helperText={errors.mobile ? "Enter Valid Mobile Number" : ""}
                    />
                </Grid>

               
                <Grid item>
                    <TextField
                        id="from_mail"
                        label="Email*"
                        variant="outlined"
                        fullWidth
                        name="from_mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={errors.email} // Show error state
                        helperText={errors.email ? "Enter Valid Email" : ""}
                    />
                </Grid>

                <Grid item>
                    <TextField
                        id="message"
                        name="message"
                        label="How Can We Help*"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        error={errors.message} // Show error state
                        helperText={errors.message ? "Message is required" : ""}
                    />
                </Grid>

                <Grid item sx={{ textAlign: 'center' }}>
                    <button type="submit" style={{ padding: '10px', width: '150px', backgroundColor: '#333333', color: 'white', border: 'none', borderRadius: '2px', cursor: 'pointer' }}>
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Submit</span>
                    </button>
                </Grid>
            </Grid>

            {statusMessage && (
                <Grid item sx={{ textAlign: 'center', marginTop: '10px' }}>
                    <Typography
                        variant="h6"
                        color={statusType === 'success' ? 'green' : 'red'}
                    >
                        {statusMessage}
                    </Typography>
                </Grid>
            )}
        </form>
    );
};

export default ContactForm;

