import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CommonRouters from './CommonRouter/router'

function App() {

  return (
    <>
      <CommonRouters />
    </>
  )
}
export default App
