
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from '../components/about';
import Test from '../test';
import Our_services from '../components/our_services';
import Contact_Us from '../Pages/contact_us';
import Explainer_Video from '../Pages/explainer_video';
import Animation_Services from '../Pages/animation_services';
import Motion_Graphics from '../Pages/motion_graphics';
import Our_Work from '../Pages/our_work';
import App from '../Pages/practice';
import VideoThumbnailExtractor from '../Pages/practice';

function CommonRouters() {


    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Test />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/our-services" element={<Our_services />} />
                    <Route path="/contact-us" element={<Contact_Us />} />
                    <Route path="/explainer-video" element={<Explainer_Video />} />
                    <Route path="/animation-services" element={<Animation_Services />} />
                    <Route path="/graphic-designing" element={<Motion_Graphics />} />
                    <Route path="/our-work" element={<Our_Work />} />
                    <Route path="/practice" element={<VideoThumbnailExtractor />} />
                </Routes>
            </Router>
        </>
    )
}

export default CommonRouters